import axios from 'axios';

export default class EnvioCelulaService {

	getCelula() {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/enviocelulas`;
		const url = `/api/enviocelulas`;
		return axios.get(url).then(res=>res.data.data);
	}
 
	getCelulaID(celula) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/enviocelulas/${celula}`;
		const url = `/api/enviocelulas/${celula}`;
		return axios.get(url).then(res=>res.data.data);
	}
	
	createCelula(celula) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/enviocelulas`;
		const url = `/api/enviocelulas`;
		return axios.post(url,celula).then(res=>res.data.data);
	}
	
	updateCelula(celula) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/enviocelulas/${celula.id_envio_celula}`;
		const url = `/api/enviocelulas/${celula.id_envio_celula}`;
		return axios.put(url,celula).then(res=>res.data.data);
	}
 	
	deleteCelula(celula) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/enviocelulas/${celula.id_envio_celula}`;
		const url = `/api/enviocelulas/${celula.id_envio_celula}`;
		return axios.delete(url).then(res=>res.data.data);
    }
}