import axios from 'axios';

export default class PadecimientoService {

	getPadecimientos() {
		//return axios.get('assets/layout/data/padecimientos.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/padecimientos`;
		const url = `/api/padecimientos`;
		return axios.get(url).then(res=>res.data.data);
	}
	
	createPadecimientos(padecimientos) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/padecimientos`;
		const url = `/api/padecimientos`;
		return axios.post(url,padecimientos).then(res=>res.data.data);
	}
	
	updatePadecimientos(padecimientos) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/padecimientos/${padecimientos.id_padecimiento}`;
		const url = `/api/padecimientos/${padecimientos.id_padecimiento}`;
		return axios.put(url,padecimientos).then(res=>res.data.data);
	}
	
	deletePadecimientos(padecimientos) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/padecimientos/${padecimientos.id_padecimiento}`;
		const url = `/api/padecimientos/${padecimientos.id_padecimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
	
	getPadecimientosExport() {
		//return axios.get('assets/layout/data/padecimientos.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/padecimientosexport`;
		const url = `/api/padecimientosexport`;
		return axios.get(url).then(res=>res.data.data);
	}
}
