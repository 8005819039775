<template>
	<div class="p-grid panel-demo dashboard">
        <div class="p-col-12">
			<div class="card">
                <Toast/>
                <Toolbar>
					<template v-slot:left>
                        <h4>Envío de Células</h4>                        
					</template>
                    <template v-slot:right>
                        <Button label="Cerrar Muestra" @click="confirmDeleteEstado()"  class="p-button-raised p-button-primary p-mr-2" :disabled="enabledButton"/>
                        <Button label="Generación Informe" @click="openNew" :disabled="enabledButton"  class="p-button-raised p-button-secondary p-mr-2" />
                        <Button label="Imprimir Etiquetas" @click="NewImprimir" :disabled="enabledButton" icon="pi pi-print" class="p-button-raised p-button-secondary p-mr-2" />
                        <Button :disabled="id_estatus == 4 || id_estatus == 3" label="Guardar Cambios" @click="SaveSendCelula" icon="pi pi-check" class="p-button-raised p-mr-2" />
					</template>
				</Toolbar>
                <div class="p-d-flex">
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Folio Muestra</span>
                             <InputNumber  id="desc" v-model="Id_muestra"  />
                            <Button label="Buscar" class="p-button-raised p-mr-2" @click="GetMuestra" />
                        </div>
                    </div>
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Doctor</span>
                            <span class="detail">{{muestra.doctor}}</span>
                        </div>
                    </div>
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Paciente</span>
                            <span class="detail">{{muestra.paciente}}</span>
                        </div>
                    </div>                
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Servicio</span>
                            <span class="detail">{{nombreservico1}}</span>
                        </div>
                    </div>
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Estatus</span>
                            <span class="detail">{{muestra.estatu}}</span>
                        </div>
                    </div>
                </div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="card">
                <TabView>
					<TabPanel header="Envío 1">
						<p>Cultivo bacteriológico <strong>antes de enviar</strong> las células</p>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="fechaEnvio">Fecha *</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fecha1"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="condiciones">Comentarios generales encontrados</label>
                                <Textarea :disabled="id_estatus == 4 || id_estatus == 3" id="condiciones" v-model="comenatarios1" required="false" rows="2" cols="20" />
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaEnvio">Fecha envío de células *</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="EnvioCelula1"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="responsableEnvio">Responsable del envío *</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="responsableEnvio" v-model="Responsable1" type="text" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="medioTransporte">Medio de transporte *</label>
                                <Dropdown :disabled="id_estatus == 4 || id_estatus == 3" id="medioTransporte" v-model="Transporte1" :options="medioTransporteDrop" optionLabel="nombre" placeholder="Seleccionar un medio de transporte..."></Dropdown>
                            </div>
                                <div class="p-field p-col-12 p-md-6">
                                <label for="medioTransporte">Nombre del medio de transporte *</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="medioTransporte" v-model="Nombretransporte1" type="text"/>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="guiaEnvio">Guía / Referencia</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="guiaEnvio" v-model="Referencia1" type="text"/>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaLlegada">Fecha llegada de células</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fechallegada1"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="condiciones">Condiciones generales de las células</label>
                                <Textarea :disabled="id_estatus == 4 || id_estatus == 3" id="condiciones" v-model="condicionesMuestra1" required="false" rows="2" cols="20" />
                            </div>
                             <!-- <Button label="Reporte de Envio" icon="pi pi-print" class="p-button-raised p-button-secondary p-mr-2" /> -->
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaLlegada">Fecha real de la aplicación</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fechaapp1"></Calendar>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Envío 2">
						<p>Cultivo bacteriológico <strong>antes de enviar</strong> las células</p>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="fechaEnvio">Fecha *</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fecha2"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="condiciones">Comentarios generales encontrados</label>
                                <Textarea :disabled="id_estatus == 4 || id_estatus == 3" id="condiciones" v-model="comenatarios2" required="false" rows="2" cols="20" />
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaEnvio">Fecha envío de células *</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="EnvioCelula2"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="responsableEnvio">Responsable del envío *</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="responsableEnvio" v-model="Responsable2" type="text" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="medioTransporte">Medio de transporte *</label>
                                <Dropdown :disabled="id_estatus == 4 || id_estatus == 3" id="medioTransporte" v-model="Transporte2" :options="medioTransporteDrop" optionLabel="nombre" placeholder="Seleccionar un medio de transporte..."></Dropdown>
                            </div>
                                <div class="p-field p-col-12 p-md-6">
                                <label for="medioTransporte">Nombre del medio de transporte *</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="medioTransporte" v-model="Nombretransporte2" type="text"/>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="guiaEnvio">Guía / Referencia</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="guiaEnvio" v-model="Referencia2" type="text"/>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaLlegada">Fecha llegada de células</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fechallegada2"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="condiciones">Condiciones generales de las células</label>
                                <Textarea :disabled="id_estatus == 4 || id_estatus == 3" id="condiciones" v-model="condicionesMuestra2" required="false" rows="2" cols="20" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaLlegada">Fecha real de la aplicación</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fechaapp2"></Calendar>
                            </div>
                        </div>
					</TabPanel>
					<TabPanel header="Envío 3" headerStyle="font-size:20px">
						<p>Cultivo bacteriológico <strong>antes de enviar</strong> las células</p>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="fechaEnvio">Fecha *</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fecha3"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="condiciones">Comentarios generales encontrados</label>
                                <Textarea :disabled="id_estatus == 4 || id_estatus == 3" id="condiciones" v-model="comenatarios3" required="false" rows="2" cols="20" />
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaEnvio">Fecha envío de células *</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="EnvioCelula3"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="responsableEnvio">Responsable del envío *</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="responsableEnvio" v-model="Responsable3" type="text" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="medioTransporte">Medio de transporte *</label>
                                <Dropdown :disabled="id_estatus == 4 || id_estatus == 3" id="medioTransporte" v-model="Transporte3" :options="medioTransporteDrop" optionLabel="nombre" placeholder="Seleccionar un medio de transporte..."></Dropdown>
                            </div>
                                <div class="p-field p-col-12 p-md-6">
                                <label for="medioTransporte">Nombre del medio de transporte *</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="medioTransporte" v-model="Nombretransporte3" type="text"/>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="guiaEnvio">Guía / Referencia</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="guiaEnvio" v-model="Referencia3" type="text"/>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaLlegada">Fecha llegada de células</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fechallegada3"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="condiciones">Condiciones generales de las células</label>
                                <Textarea :disabled="id_estatus == 4 || id_estatus == 3" id="condiciones" v-model="condicionesMuestra3" required="false" rows="2" cols="20" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaLlegada">Fecha real de la aplicación</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fechaapp3"></Calendar>
                            </div>
                        </div>
					</TabPanel>
                    <TabPanel header="Envío 4">
						<p>Cultivo bacteriológico <strong>antes de enviar</strong> las células</p>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="fechaEnvio">Fecha *</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fecha4"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="condiciones">Comentarios generales encontrados *</label>
                                <Textarea :disabled="id_estatus == 4 || id_estatus == 3" id="condiciones" v-model="comenatarios4" required="false" rows="2" cols="20" />
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaEnvio">Fecha envío de células *</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="EnvioCelula4"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="responsableEnvio">Responsable del envío *</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="responsableEnvio" v-model="Responsable4" type="text" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="medioTransporte">Medio de transporte *</label>
                                <Dropdown :disabled="id_estatus == 4 || id_estatus == 3" id="medioTransporte" v-model="Transporte4" :options="medioTransporteDrop" optionLabel="nombre" placeholder="Seleccionar un medio de transporte..."></Dropdown>
                            </div>
                                <div class="p-field p-col-12 p-md-6">
                                <label for="medioTransporte">Nombre del medio de transporte *</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="medioTransporte" v-model="Nombretransporte4" type="text"/>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="guiaEnvio">Guía / Referencia</label>
                                <InputText :disabled="id_estatus == 4 || id_estatus == 3" id="guiaEnvio" v-model="Referencia4" type="text"/>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaLlegada">Fecha llegada de células *</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fechallegada4"></Calendar>
                            </div>
                            <div class="p-field p-col-12 p-md-12">
                                <label for="condiciones">Condiciones generales de las células *</label>
                                <Textarea :disabled="id_estatus == 4 || id_estatus == 3" id="condiciones" v-model="condicionesMuestra4" required="false" rows="2" cols="20" />
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="fechaLlegada">Fecha real de la aplicación *</label>
                                <Calendar dateFormat="dd/mm/yy" :disabled="id_estatus == 4 || id_estatus == 3" :showIcon="true" :showButtonBar="true" v-model="Fechaapp4"></Calendar>
                            </div>
                        </div>
					</TabPanel>


				</TabView>
                <Dialog v-model:visible="deleteEstadoDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span>¿Desea cerrar la muestra<b></b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteEstadoDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="CerrarMuestra" />
					</template>
				</Dialog>
			</div>
		</div>  

         <Dialog v-model:visible="descuentoDialog" :style="{width: '450px'}" header="Generación de Informe" :modal="true" class="p-fluid">
					 
                    <div class="p-field">
                        <label for="motivodescuento">No de Envío *</label>
                        <Dropdown id="dropdown" :options="EnviosDrop" v-model="SelectEnvio" optionLabel="num"></Dropdown>
                    </div>
					<div class="p-field">
						<label for="motivodescuento">Descripcion *</label>
						<Textarea id="motivodescuento" v-model.trim="descripcion" required="true" autofocus :class="{'p-invalid': submitted && !descripcion}" />
						<small class="p-invalid" v-if="submitted && !descripcion">La descripcion es requerido</small>
					</div>

					<!-- <div class="p-field">
						<label for="comentarios">Comentarios</label>
						<InputText id="comentarios" v-model.trim="comentarios" />
					</div> -->

					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Generar Reporte" icon="pi pi-check" class="p-button-text" @click="genearareporte" />
					</template>
				</Dialog>  

                 <Dialog v-model:visible="imprimirdialog" :style="{width: '450px'}" header="Imprimir" :modal="true" class="p-fluid">
					 

				 <div class="p-field p-col-12 p-md-12 p-grid">
                               
                                <div class="p-field p-col">
                                    <label for="grande">No. de Etiquetas exteriores</label>
                                    <InputNumber id="grande" inputStyle="text-align: center;" v-model="etiquetasExteriores" integeronly />
                                </div>
                                <div class="p-field p-col" >
                                    <label for="chica">No. de Etiquetas en tubos</label>
                                    <InputNumber  id="chica" inputStyle="text-align: center;" v-model="etiquetasTubos" integeronly />
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-12 p-grid">
								<div class="p-field p-col">
									  <label for="chica">Servicio</label>
									<Dropdown id="paciente" v-model="SelectServicosImprimir" :options="ServicosImprimir" optionLabel="Nombre" style="width:95%"></Dropdown>
								</div>
							</div>


					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog1"/>
						<Button label="Imprimir" icon="pi pi-check" class="p-button-text" @click="ImprimirEt" />
					</template>
				</Dialog> 

	</div>
</template>
<script>
import DoctorService from '../service/DoctorService';
import PadecimientoService from '../service/PadecimientoService';
import ServicioService from '../service/ServicioService';
import MuestraService from '../service/MuestraService';
import EnvioCelulaService from '../service/EnvioCelulaService';
import RecambioService from '../service/RecambioService';
import SolicitudMuestraService from '../service/SolicitudMuestraService';
import { jsPDF } from "jspdf";
import moment from 'moment';
import mqtt from 'mqtt'

export default {
		data() {
			return {
                EnviosDrop:null,
                FechaEnvioReport:null,
                SelectEnvio:null,
                etiquetasExteriores:1,
                etiquetasTubos:1,
                enabledButton:true,
                imprimirdialog:false,
                porcdescuento: 0,
                Id_muestra:0,
                motivodescuento: null,
                descuentoDialog: false,
                servicios: null,
                selectedServicios: null,
                selectedAutoValueDoctor: null,
                autoFilteredValueDoctor: [],
                selectedAutoValuePadecimiento: null,
                autoFilteredValuePadecimiento: [],
                calendarValue: null,
                submitted: false,
                condicionesMuestra: null,
                descripcion:null,

                Fecha1:null,
                comenatarios1:null,
                EnvioCelula1:null,
                Responsable1:null,
                Transporte1:null,
                Nombretransporte1:null,
                Referencia1:null,
                Fechallegada1:null,
                condicionesMuestra1:null,
                Fechaapp1:null,

                Fecha2:null,
                comenatarios2:null,
                EnvioCelula2:null,
                Responsable2:null,
                Transporte2:null,
                Nombretransporte2:null,
                Referencia2:null,
                Fechallegada2:null,
                condicionesMuestra2:null,
                Fechaapp2:null,

                Fecha3:null,
                comenatarios3:null,
                EnvioCelula3:null,
                Responsable3:null,
                Transporte3:null,
                Nombretransporte3:null,
                Referencia3:null,
                Fechallegada3:null,
                condicionesMuestra3:null,
                Fechaapp3:null,

                Fecha4:null,
                comenatarios4:null,
                EnvioCelula4:null,
                Responsable4:null,
                Transporte4:null,
                Nombretransporte4:null,
                Referencia4:null,
                Fechallegada4:null,
                condicionesMuestra4:null,
                Fechaapp4:null,

                dropdownTransportes: [
					{nombre: 'Transporte propio',value:1},
					{nombre: 'Transportación terrestre',value:2},
                    {nombre: 'Transportación aérea',value:3},
                    {nombre: 'Otro medio de transporte',value:4},
				],
				dropdownTransporte: null,
				dropdownServicios: [
					{nombre: 'Cultivo de Grasa'},
					{nombre: 'Cultivo de Médulo Ósea'},
                    {nombre: 'Cultivo de Sangre Periférica'},
                    {nombre: 'Cultivo de Piel'}
				],
                dropdownServicio: null,
                muestra:{},
                celulaPost1:[],
                celulaPost2:[],
                celulaPost3:[],
                celulaPost4:[],
                ResponseCelula1:null,
                ResponseCelula2:null,
                ResponseCelula3:null,
                ResponseCelula4:null,
                ReponseCelulas:null,

                Fechallegada11:null,
                condicionesMuestra11:null,
                Fechaapp11:null,

                Fechallegada22:null,
                condicionesMuestra22:null,
                Fechaapp22:null,

                Fechallegada33:null,
                condicionesMuestra33:null,
                Fechaapp33:null,

                Fechallegada44:null,
                condicionesMuestra44:null,
                Fechaapp44:null, 
                
                deleteEstadoDialog: false,
                EstatusCambio:[],
                id_estatus:null,
                 serviciosN:null,
                nombreservico:null,
                nombreservico1:null,
                 ImpPost:[], 
                medioTransporteDrop:null,
                connection: {
				host: 'broker.emqx.io',
				port: 8084,
                // port: 8083,
                endpoint: '/mqtt',
				clean: true, // 
				connectTimeout: 4000, // 
				reconnectPeriod: 4000, // 
				clientId: 'mqttjs_3be2c321',
				username: 'emqx_test',
				password: 'emqx_test',
                },
                subscription: {
                    topic: 'topic/mqttx',
                    qos: 0,
                },
                publish: {
                    topic: 'sgcells/etiqueta',
                    qos: 0,
                    payload: '{ "msg": "Hello, I am browser." }',
                },
                receiveNews: '',
                qosList: [
                    { label: 0, value: 0 },
                    { label: 1, value: 1 },
                    { label: 2, value: 2 },
                ],
                client: {
                    connected: false,
                },
                subscribeSuccess: false,
                ServicosImprimir:[],
			    SelectServicosImprimir:null,
            }
        },
        doctorService: null,
        padecimientoService: null,
        servicioService: null,
        muestraservice:null,
        enviocelulaservice:null,
         recambioService: null,
         solicitudmuestraService:null,
		created() {
            this.doctorService = new DoctorService();
            this.padecimientoService = new PadecimientoService();
            this.servicioService = new ServicioService();
            this.muestraservice = new MuestraService();
            this.enviocelulaservice = new EnvioCelulaService();
            this.recambioService = new RecambioService();
        },
        components:{
            
        },
		mounted() {
            // this.doctorService.getDoctoresCombo().then(data => this.autoValue = data);
            this.padecimientoService.getPadecimientos().then(data => this.autoValue = data);
            this.servicioService.getServiciosPrecios().then(data => this.servicios = data);
             this.recambioService.getrecambioserviceID(this.Id_muestra).then(data => this.recambios = data);
             this.solicitudmuestraService = new SolicitudMuestraService();
               this.muestraservice.getmMediotransportes().then(data => this.medioTransporteDrop = data);
		},
        methods: {	
            SaveSendCelula(){
                if(this.Id_muestra !=0){                    

                    if(this.ResponseCelula1 ==null){
                        if(this.Fecha1 != null && this.EnvioCelula1 != null  && this.Transporte1 != null) {

                            if(this.Fechallegada1 != null){
                                this.Fechallegada11=this.computedDates(this.Fechallegada1);
                            }

                            if(this.Fechaapp1 != null){
                                this.Fechaapp11=this.computedDates(this.Fechaapp1);
                            }


                            this.celulaPost1.push({
                                "id_muestra":this.Id_muestra,
                                "fecha_cultivo_bac":this.computedDates(this.Fecha1),
                                "comentarios":this.comenatarios1,
                                "no_envio":1,
                                "fecha_envio":this.computedDates(this.EnvioCelula1),
                                "fecha_llegada":this.Fechallegada11,
                                "responsable_envio":this.Responsable1,
                                "id_medio_transporte":this.Transporte1.id_medio_transporte,
                                "nombre_medio_trans":this.Nombretransporte1,
                                "guia":this.Referencia1,
                                "condiciones":this.condicionesMuestra1,
                                "fecha_real_aplicacion":this.Fechaapp11,
                            });
                            console.log('celula1',this.celulaPost1);
                            this.enviocelulaservice.createCelula(this.celulaPost1[0]).then(data => this.ResponseCelula1 = data);
                             this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente', life: 3000});
                            this.celulaPost1=[];
                        }
                    }else{

                        if(this.Fechallegada1 != null){
                                this.Fechallegada11=this.computedDates(this.Fechallegada1);
                            }

                            if(this.Fechaapp1 != null){
                                this.Fechaapp11=this.computedDates(this.Fechaapp1);
                            }

                        console.log('Celula 1 ipd',this.ResponseCelula1);
                        this.celulaPost1.push({
                            "id_envio_celula":this.ResponseCelula1.id_envio_celula,
                            "id_muestra":this.Id_muestra,
                            "fecha_cultivo_bac":this.computedDates(this.Fecha1),
                            "comentarios":this.comenatarios1,
                            "no_envio":1,
                            "fecha_envio":this.computedDates(this.EnvioCelula1),
                            "fecha_llegada":this.Fechallegada11,
                            "responsable_envio":this.Responsable1,
                            "id_medio_transporte":this.Transporte1.id_medio_transporte,
                            "nombre_medio_trans":this.Nombretransporte1,
                            "guia":this.Referencia1,
                            "condiciones":this.condicionesMuestra1,
                            "fecha_real_aplicacion":this.Fechaapp11,
                        });
                        console.log(this.celulaPost1);
                        this.enviocelulaservice.updateCelula(this.celulaPost1[0]).then();
                         this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente', life: 3000});
                    }
                    this.celulaPost1=[];   
                    ///2
                    if(this.ResponseCelula2 ==null){
                        if(this.Fecha2 != null && this.EnvioCelula2 != null  && this.Transporte2 != null) 
                        {

                            if(this.Fechallegada2 != null){
                                    this.Fechallegada22=this.computedDates(this.Fechallegada2);
                                }

                                if(this.Fechaapp2 != null){
                                    this.Fechaapp22=this.computedDates(this.Fechaapp2);
                                }

                            this.celulaPost2.push({ 
                                "id_muestra":this.Id_muestra,
                                "fecha_cultivo_bac":this.computedDates(this.Fecha2),
                                "comentarios":this.comenatarios2,
                                "no_envio":2,
                                "fecha_envio":this.computedDates(this.EnvioCelula2),
                                "fecha_llegada":this.Fechallegada22,
                                "responsable_envio":this.Responsable2,
                                "id_medio_transporte":this.Transporte2.id_medio_transporte,
                                "nombre_medio_trans":this.Nombretransporte2,
                                "guia":this.Referencia2,
                                "condiciones":this.condicionesMuestra2,
                                "fecha_real_aplicacion":this.Fechaapp22,
                            });

                            this.enviocelulaservice.createCelula(this.celulaPost2[0]).then(data => this.ResponseCelula2 = data);
                            this.celulaPost2=[];
                             this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente', life: 3000});
                        }
                    }else{

                        if(this.Fechallegada2 != null){
                                this.Fechallegada22=this.computedDates(this.Fechallegada2);
                            }

                            if(this.Fechaapp2 != null){
                                this.Fechaapp22=this.computedDates(this.Fechaapp2);
                            }

                        // console.log(this.ResponseCelula2);
                        this.celulaPost2.push({
                            "id_envio_celula":this.ResponseCelula2.id_envio_celula,
                            "id_muestra":this.Id_muestra,
                            "fecha_cultivo_bac":this.computedDates(this.Fecha2),
                            "comentarios":this.comenatarios2,
                            "no_envio":2,
                            "fecha_envio":this.computedDates(this.EnvioCelula2),
                            "fecha_llegada":this.Fechallegada22,
                            "responsable_envio":this.Responsable2,
                            "id_medio_transporte":this.Transporte2.id_medio_transporte,
                            "nombre_medio_trans":this.Nombretransporte2,
                            "guia":this.Referencia2,
                            "condiciones":this.condicionesMuestra2,
                            "fecha_real_aplicacion":this.Fechaapp22,
                        }); 
                        this.enviocelulaservice.updateCelula(this.celulaPost2[0]).then();
                         this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente', life: 3000});
                    }
                    this.celulaPost2=[];  

                    //3
                    if(this.ResponseCelula3 ==null){
                        if(this.Fecha3 != null && this.EnvioCelula3 != null  && this.Transporte3 != null) 
                        {
                            if(this.Fechallegada3 != null){
                                    this.Fechallegada33=this.computedDates(this.Fechallegada3);
                                }

                            if(this.Fechaapp3 != null){
                                this.Fechaapp33=this.computedDates(this.Fechaapp3);
                            }


                            
                            this.celulaPost3.push({ 
                                "id_muestra":this.Id_muestra,
                                "fecha_cultivo_bac":this.computedDates(this.Fecha3),
                                "comentarios":this.comenatarios3,
                                "no_envio":3,
                                "fecha_envio":this.computedDates(this.EnvioCelula3),
                                "fecha_llegada":this.Fechallegada33,
                                "responsable_envio":this.Responsable3,
                                "id_medio_transporte":this.Transporte3.id_medio_transporte,
                                "nombre_medio_trans":this.Nombretransporte3,
                                "guia":this.Referencia3,
                                "condiciones":this.condicionesMuestra3,
                                "fecha_real_aplicacion":this.Fechaapp33,
                            });

                            this.enviocelulaservice.createCelula(this.celulaPost3[0]).then(data => this.ResponseCelula3 = data);
                             this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente', life: 3000});
                            this.celulaPost3=[];
                        }
                    }else{

                         if(this.Fechallegada3 != null){
                                this.Fechallegada33=this.computedDates(this.Fechallegada3);
                            }

                        if(this.Fechaapp3 != null){
                               this.Fechaapp33=this.computedDates(this.Fechaapp3);
                        }

                        // console.log(this.ResponseCelula2);
                        this.celulaPost3.push({
                            "id_envio_celula":this.ResponseCelula3.id_envio_celula,
                            "id_muestra":this.Id_muestra,
                            "fecha_cultivo_bac":this.computedDates(this.Fecha3),
                            "comentarios":this.comenatarios3,
                            "no_envio":3,
                            "fecha_envio":this.computedDates(this.EnvioCelula3),
                            "fecha_llegada":this.Fechallegada33,
                            "responsable_envio":this.Responsable3,
                            "id_medio_transporte":this.Transporte3.id_medio_transporte,
                            "nombre_medio_trans":this.Nombretransporte3,
                            "guia":this.Referencia2,
                            "condiciones":this.condicionesMuestra3,
                            "fecha_real_aplicacion":this.Fechaapp33,
                        }); 
                        this.enviocelulaservice.updateCelula(this.celulaPost3[0]).then();
                         this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente', life: 3000});
                    }
                    this.celulaPost3=[];  
                    
                     ///4
                    if(this.ResponseCelula4 ==null){
                        if(this.Fecha4 != null && this.EnvioCelula4 != null  && this.Transporte4 != null) 
                        {

                            if(this.Fechallegada4 != null){
                                    this.Fechallegada44=this.computedDates(this.Fechallegada4);
                                }

                            if(this.Fechaapp4 != null){
                                this.Fechaapp44=this.computedDates(this.Fechaapp4);
                            }


                            this.celulaPost4.push({ 
                                "id_muestra":this.Id_muestra,
                                "fecha_cultivo_bac":this.computedDates(this.Fecha4),
                                "comentarios":this.comenatarios4,
                                "no_envio":4,
                                "fecha_envio":this.computedDates(this.EnvioCelula4),
                                "fecha_llegada":this.Fechallegada44,
                                "responsable_envio":this.Responsable4,
                                "id_medio_transporte":this.Transporte4.id_medio_transporte,
                                "nombre_medio_trans":this.Nombretransporte4,
                                "guia":this.Referencia4,
                                "condiciones":this.condicionesMuestra4,
                                "fecha_real_aplicacion":this.Fechaapp44,
                            });

                            this.enviocelulaservice.createCelula(this.celulaPost4[0]).then(data => this.ResponseCelula4 = data);
                             this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente', life: 3000});
                            this.celulaPost4=[];
                        }
                    }else{
                            if(this.Fechallegada4 != null){
                                    this.Fechallegada44=this.computedDates(this.Fechallegada4);
                                }

                            if(this.Fechaapp4 != null){
                                this.Fechaapp44=this.computedDates(this.Fechaapp4);
                            }
                        // console.log(this.ResponseCelula2);
                        this.celulaPost4.push({
                            "id_envio_celula":this.ResponseCelula4.id_envio_celula,
                            "id_muestra":this.Id_muestra,
                            "fecha_cultivo_bac":this.computedDates(this.Fecha4),
                            "comentarios":this.comenatarios4,
                            "no_envio":4,
                            "fecha_envio":this.computedDates(this.EnvioCelula4),
                            "fecha_llegada":this.Fechallegada44,
                            "responsable_envio":this.Responsable4,
                            "id_medio_transporte":this.Transporte4.id_medio_transporte,
                            "nombre_medio_trans":this.Nombretransporte4,
                            "guia":this.Referencia4,
                            "condiciones":this.condicionesMuestra4,
                            "fecha_real_aplicacion":this.Fechaapp44,
                        }); 
                        this.enviocelulaservice.updateCelula(this.celulaPost4[0]).then();
                         this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente', life: 3000});
                    }
                    this.celulaPost4=[];  
                    
                }              
            },
            GetMuestra(){
                if(this.Id_muestra !=0){
                    this.muestraservice.getMuestrasID(this.Id_muestra).then(data =>{
                         if(typeof(data.data1[0]) !='undefined'){
                        this.muestra = data.data1[0];
                        this.enabledButton=false;
                        console.log(data)
                         this.muestra.paciente = data.data1[0].paciente+" "+data.data1[0].pacienteApP+" "+data.data1[0].pacienteApM;
                        this.clave_estado=data.data1[0].clave_estado;
                        this.id_estatus=data.data1[0].id_estatus;
                        this.serviciosN=data.data2;
                        this.nombreservico=null;
                        console.log(this.muestra);
                        if(this.serviciosN != null){
                        for(var id1 in this.serviciosN){                           
                            this.nombreservico=this.nombreservico+' '+this.serviciosN[id1].nombre;
                            this.nombreservico1=this.nombreservico.replace("null", "");
                        }
                        }
                    }else{
                        this.enabledButton=true;
                        this.muestra.doctor = ''; 
                        this.muestra.paciente = ''; 
                        this.nombreservico1 = ''; 
                        this.muestra.estatu = ''; 
                        this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'No existe el folio de Muestra ', life: 4000});
                        this.Id_muestra=0;
                    }
                    
                    }); 
//ReponseCelulas    
                    this.enviocelulaservice.getCelulaID(this.Id_muestra).then(data => {
                        this.ReponseCelulas = data
                         var dias = 1;
                    if(this.ReponseCelulas != null){

                        this.ResponseCelula1=this.ReponseCelulas[0];
                        this.ResponseCelula2=this.ReponseCelulas[1];
                        this.ResponseCelula3=this.ReponseCelulas[2];
                        this.ResponseCelula4=this.ReponseCelulas[3];

                        if(this.ResponseCelula1 !=null){

                             // Número de días a agregar
                            this.Fecha1=new Date(this.ResponseCelula1.fecha_cultivo_bac);
                            this.Fecha1.setDate(this.Fecha1.getDate() + dias);
                            // console.info(this.Fecha1);
                            

                            this.comenatarios1=this.ResponseCelula1.comentarios;

                            this.EnvioCelula1=new Date(this.ResponseCelula1.fecha_envio);
                            this.EnvioCelula1.setDate(this.EnvioCelula1.getDate() + dias);
                          
                            if(this.ResponseCelula1.fecha_llegada !=null){
                                this.Fechallegada1=new Date(this.ResponseCelula1.fecha_llegada);
                                this.Fechallegada1.setDate(this.Fechallegada1.getDate() + dias);                               
                            }else{
                                this.Fechallegada1=this.ResponseCelula1.fecha_llegada;
                            }

                            this.Responsable1=this.ResponseCelula1.responsable_envio;
                            for(var i in this.medioTransporteDrop){
                                
                                if(this.ResponseCelula1.id_medio_transporte==this.medioTransporteDrop[i].id_medio_transporte)
                                {                                    
                                    this.Transporte1 = this.medioTransporteDrop[i];     
                                    // console.log(this.dropdownTransporteEnvio);                               
                                }                  	
                            } 

                            this.Nombretransporte1=this.ResponseCelula1.nombre_medio_trans;
                            this.Referencia1=this.ResponseCelula1.guia;
                            this.condicionesMuestra1=this.ResponseCelula1.condiciones;
                            // this.Fechaapp1=this.ResponseCelula1.fecha_real_aplicacion;
                            // this.Fechaapp1 = new Date(this.ResponseCelula1.fecha_real_aplicacion);
                            if(this.ResponseCelula1.fecha_real_aplicacion != null){
                                this.Fechaapp1=new Date(this.ResponseCelula1.fecha_real_aplicacion);
                                this.Fechaapp1.setDate(this.Fechaapp1.getDate() + dias);
                                //  this.Fechaapp1 = new Date(this.ResponseCelula1.fecha_real_aplicacion);
                                  
                            }else  {
                                this.Fechaapp1=this.ResponseCelula1.fecha_real_aplicacion;
                            }

                             console.log(this.ResponseCelula1);
                        }else{
                            this.Fecha1=null;
                            this.comenatarios1=null;                               
                            this.EnvioCelula1=null;
                            this.Fechallegada1=null;
                            this.Responsable1=null;
                            this.Transporte1=null;
                            this.Nombretransporte1=null;
                            this.Referencia1=null;
                            this.condicionesMuestra1=null;
                            this.Fechaapp1=null;
                        }

                        if(this.ResponseCelula2 !=null){
                            
                            this.Fecha2=new Date(this.ResponseCelula2.fecha_cultivo_bac);
                            this.Fecha2.setDate(this.Fecha2.getDate() + dias);

                            this.comenatarios2=this.ResponseCelula2.comentarios;     
                            
                            
                            // this.EnvioCelula2=this.ResponseCelula2.fecha_envio;
                            // this.EnvioCelula2 = new Date(this.ResponseCelula2.fecha_envio);
                            this.EnvioCelula2=new Date(this.ResponseCelula2.fecha_envio);
                            this.EnvioCelula2.setDate(this.EnvioCelula2.getDate() + dias);

                            // this.Fechallegada2=this.ResponseCelula2.fecha_llegada;
                            if(this.ResponseCelula2.fecha_llegada !=null){
                                this.Fechallegada2=new Date(this.ResponseCelula2.fecha_llegada);
                                this.Fechallegada2.setDate(this.Fechallegada2.getDate() + dias);

                                // this.Fechallegada2 = new Date(this.ResponseCelula2.fecha_llegada);
                            }else{
                                this.Fechallegada2=this.ResponseCelula2.fecha_llegada;
                            }
                            


                            this.Responsable2=this.ResponseCelula2.responsable_envio;
                            // this.Transporte2=this.ResponseCelula2.id_medio_transporte;
                            for(var i1 in this.medioTransporteDrop){
                                
                                if(this.ResponseCelula2.id_medio_transporte==this.medioTransporteDrop[i1].id_medio_transporte)
                                {                                    
                                    this.Transporte2 = this.medioTransporteDrop[i1];     
                                    // console.log(this.dropdownTransporteEnvio);                               
                                }                  	
                            } 
                            this.Nombretransporte2=this.ResponseCelula2.nombre_medio_trans;
                            this.Referencia2=this.ResponseCelula2.guia;
                            this.condicionesMuestra2=this.ResponseCelula2.condiciones;

                            if(this.ResponseCelula2.fecha_real_aplicacion != null){
                                //  this.Fechaapp2 = new Date(this.ResponseCelula2.fecha_real_aplicacion);
                                  this.Fechaapp2=new Date(this.ResponseCelula2.fecha_real_aplicacion);
                                this.Fechaapp2.setDate(this.Fechaapp2.getDate() + dias);

                                  
                            }else  {
                                this.Fechaapp2=this.ResponseCelula2.fecha_real_aplicacion;
                            }
                            
                           console.log(this.ResponseCelula2);
                            // this.Fechaapp2=this.ResponseCelula2.fecha_real_aplicacion;
                        }else{
                            this.Fecha2=null;
                            this.comenatarios2=null;                               
                            this.EnvioCelula2=null;
                            this.Fechallegada2=null;
                            this.Responsable2=null;
                            this.Transporte2=null;
                            this.Nombretransporte2=null;
                            this.Referencia2=null;
                            this.condicionesMuestra2=null;
                            this.Fechaapp2=null;
                        }

                        if(this.ResponseCelula3 !=null){
                            // this.Fecha3=this.ResponseCelula3.fecha_cultivo_bac;
                            // this.Fecha3 = new Date(this.ResponseCelula3.fecha_cultivo_bac);
                            this.Fecha3=new Date(this.ResponseCelula3.fecha_cultivo_bac);
                            this.Fecha3.setDate(this.Fecha3.getDate() + dias);

                            this.comenatarios3=this.ResponseCelula3.comentarios;                                
                            // this.EnvioCelula3=this.ResponseCelula3.fecha_envio;
                            // this.EnvioCelula3 = new Date(this.ResponseCelula3.fecha_envio);
                             this.EnvioCelula3=new Date(this.ResponseCelula3.fecha_envio);
                            this.EnvioCelula3.setDate(this.EnvioCelula3.getDate() + dias);

                            // this.EnvioCelula3=this.ResponseCelula3.fecha_llegada;
                            // this.EnvioCelula3 = new Date(this.ResponseCelula3.fecha_llegada);
                            if(this.ResponseCelula3.fecha_llegada !=null){
                                // this.Fechallegada3 = new Date(this.ResponseCelula3.fecha_llegada);
                                this.Fechallegada3=new Date(this.ResponseCelula3.fecha_llegada);
                                this.Fechallegada3.setDate(this.Fechallegada3.getDate() + dias);
                            }else{
                                this.Fechallegada3=this.ResponseCelula3.fecha_llegada;
                            }

                            this.Responsable3=this.ResponseCelula3.responsable_envio;
                            // this.Transporte3=this.ResponseCelula3.id_medio_transporte;
                            for(var i3 in this.medioTransporteDrop){
                                
                                if(this.ResponseCelula3.id_medio_transporte==this.medioTransporteDrop[i3].id_medio_transporte)
                                {                                    
                                    this.Transporte3 = this.medioTransporteDrop[i3];     
                                    // console.log(this.dropdownTransporteEnvio);                               
                                }                  	
                            } 
                            this.Nombretransporte3=this.ResponseCelula3.nombre_medio_trans;
                            this.Referencia3=this.ResponseCelula3.guia;
                            this.condicionesMuestra3=this.ResponseCelula3.condiciones;
                            // this.Fechaapp3=this.ResponseCelula3.fecha_real_aplicacion;
                            //  this.Fechaapp3 = new Date(this.ResponseCelula3.fecha_real_aplicacion);
                            if(this.ResponseCelula3.fecha_real_aplicacion != null){
                                //  this.Fechaapp3 = new Date(this.ResponseCelula3.fecha_real_aplicacion);
                                  this.Fechaapp3=new Date(this.ResponseCelula3.fecha_real_aplicacion);
                                this.Fechaapp3.setDate(this.Fechaapp3.getDate() + dias);
                            }else  {
                                this.Fechaapp3=this.ResponseCelula3.fecha_real_aplicacion;
                            }
                        }else{
                            this.Fecha3=null;
                            this.comenatarios3=null;                               
                            this.EnvioCelula3=null;
                            this.Fechallegada3=null;
                            this.Responsable3=null;
                            this.Transporte3=null;
                            this.Nombretransporte3=null;
                            this.Referencia3=null;
                            this.condicionesMuestra3=null;
                            this.Fechaapp3=null;
                        }

                        if(this.ResponseCelula4 !=null){
                            // this.Fecha4=this.ResponseCelula4.fecha_cultivo_bac;
                            // this.Fecha4 = new Date(this.ResponseCelula4.fecha_cultivo_bac);
                            this.Fecha4=new Date(this.ResponseCelula4.fecha_cultivo_bac);
                            this.Fecha4.setDate(this.Fecha4.getDate() + dias);

                            this.comenatarios4=this.ResponseCelula4.comentarios;                                
                            // this.EnvioCelula4=this.ResponseCelula4.fecha_envio;
                            // this.EnvioCelula4 = new Date(this.ResponseCelula4.fecha_envio);
                            this.EnvioCelula4=new Date(this.ResponseCelula4.fecha_envio);
                            this.EnvioCelula4.setDate(this.EnvioCelula4.getDate() + dias);

                            // this.Fecha4=this.ResponseCelula4.fecha_llegada;
                            this.Fechallegada4 = new Date(this.ResponseCelula4.fecha_llegada);
                            if(this.ResponseCelula4.fecha_llegada !=null){
                                // this.Fechallegada4 = new Date(this.ResponseCelula4.fecha_llegada);
                                this.Fechallegada4=new Date(this.ResponseCelula4.fecha_llegada);
                                this.Fechallegada4.setDate(this.Fechallegada4.getDate() + dias);
                            }else{
                                this.Fechallegada4=this.ResponseCelula4.fecha_llegada;
                            }


                            this.Responsable4=this.ResponseCelula4.responsable_envio;
                            // this.Transporte4=this.ResponseCelula4.id_medio_transporte;
                             for(var i4 in this.medioTransporteDrop){
                                
                                if(this.ResponseCelula4.id_medio_transporte==this.medioTransporteDrop[i4].id_medio_transporte)
                                {                                    
                                    this.Transporte4 = this.medioTransporteDrop[i4];     
                                    // console.log(this.dropdownTransporteEnvio);                               
                                }                  	
                            } 
                            this.Nombretransporte4=this.ResponseCelula4.nombre_medio_trans;
                            this.Referencia4=this.ResponseCelula4.guia;
                            this.condicionesMuestra4=this.ResponseCelula4.condiciones;

                            // this.Fechaapp4=this.ResponseCelula4.fecha_real_aplicacion;
                            
                            // this.Fechaapp4 = new Date(this.ResponseCelula4.fecha_real_aplicacion);
                            if(this.ResponseCelula4.fecha_real_aplicacion != null){
                                //  this.Fechaapp4 = new Date(this.ResponseCelula4.fecha_real_aplicacion);
                                 this.Fechaapp4=new Date(this.ResponseCelula4.fecha_real_aplicacion);
                                this.Fechaapp4.setDate(this.Fechaapp4.getDate() + dias);
                            }else  {
                                this.Fechaapp4=this.ResponseCelula4.fecha_real_aplicacion;
                            }
                        }else{
                            this.Fecha4=null;
                            this.comenatarios4=null;                               
                            this.EnvioCelula4=null;
                            this.Fechallegada4=null;
                            this.Responsable4=null;
                            this.Transporte4=null;
                            this.Nombretransporte4=null;
                            this.Referencia4=null;
                            this.condicionesMuestra4=null;
                            this.Fechaapp4=null;
                        }

                        
                    }
                    this.EnviosDrop=null;
                    const ListNum=[];

                    if(this.ResponseCelula1 !=null){
                        ListNum.push({
                            "num":1,
                        })
                    }
                    if(this.ResponseCelula2 !=null){
                        ListNum.push({
                            "num":2,
                        })
                    }
                    if(this.ResponseCelula3 !=null){
                        ListNum.push({
                            "num":3,
                        })
                    }
                    if(this.ResponseCelula4 !=null){
                        ListNum.push({
                            "num":4,
                        })
                    }
                    this.EnviosDrop=ListNum;    
                    });

                                    
                    
                }
            },		
            openNew() {
                this.submitted = false;
                this.descuentoDialog = true;
            },
            hideDialog() {
			    this.descuentoDialog = false;
			    this.submitted = false;
            },
            confirmDeleteEstado(){
                if(this.Id_muestra !=0){
                    this.deleteEstadoDialog = true;
                }
                
            },
            CerrarMuestra(){

            this.EstatusCambio.push({
				"id_muestra":this.Id_muestra,
				"id_estatus":3,
				"motivo_descuento":this.muestra.motivo_descuento,
				"porc_descuento":this.muestra.porc_descuento,
			})
			console.log(this.EstatusCambio);
			this.solicitudmuestraService.updateSolicitudMuestra(this.EstatusCambio[0]).then();
            this.deleteEstadoDialog = false;
            },
            genearareporte(){

                let ValidateReport=true;

                if(this.SelectEnvio == null){
                    ValidateReport=false;
                    this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'Favor de seleccionar un No. de envio', life: 4000});
                }
                if(this.descripcion == null){
                    ValidateReport=false;
                    this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'Favor de ingresar una descripción', life: 4000});
                }

                if(ValidateReport){
                    const doc = new jsPDF('portrait', 'mm', 'a4');
                    this.EstatusCambio.push({
                    "id_muestra":this.Id_muestra,
                    "motivo_descuento":this.muestra.motivo_descuento+'.',				 
                    "descripcionreporte":this.descripcion,
                    }) 
                    this.solicitudmuestraService.updateSolicitudMuestra(this.EstatusCambio[0]).then();
                    this.deleteEstadoDialog = false;

                    // const logo = require('@/assets/layout/images/sg.jpeg')

                    const FechaReg=Date.now();
                    
                    const Fechamx=this.computedDatesMx(FechaReg);

                     

                    if(this.SelectEnvio.num ==1){
                        this.FechaEnvioReport=this.ResponseCelula1.fecha_envio;                       
                    }

                    if(this.SelectEnvio.num ==2){
                        this.FechaEnvioReport=this.ResponseCelula2.fecha_envio;                       
                    }

                    if(this.SelectEnvio.num ==3){
                        this.FechaEnvioReport=this.ResponseCelula3.fecha_envio;                       
                    }

                    if(this.SelectEnvio.num ==4){
                        this.FechaEnvioReport=this.ResponseCelula4.fecha_envio;                       
                    }
                    
                    // var imgLogo = new Image()
                    // imgLogo.src = "/assets/layout/images/sg.jpeg";
                    // doc.addImage(imgLogo, 'jpeg', 24, 24, 24, 8)
                    doc.setFontSize(12);
                    doc.text("Reporte Envío de Células", 80, 15);

                    doc.text("Fecha Reporte: ",140, 18);
                    doc.text((String)(Fechamx),170, 18);

                    doc.text("No. Envío : ",140, 25);
                    doc.text((String)(this.SelectEnvio.num),165 , 25);

                    doc.text("Fecha de Envío : ",140, 32);
                    doc.text((String)(this.computedDatesMxRepor(this.FechaEnvioReport)),175, 32);

                    doc.text("Doctor: ", 20, 40);
                    doc.text(this.muestra.doctor,35, 40);

                    doc.text("Folio de Muestra: ", 140, 40);
                    doc.text((String)(this.Id_muestra),175, 40);

                    doc.text("Paciente: ", 20, 50);
                    doc.text(this.muestra.paciente,40, 50);

                    doc.text("Servicios: ", 20, 60);
                    doc.text(this.nombreservico1,40, 60);

                    doc.text("Descripción: ", 20, 70);
                    var splitTitle = doc.splitTextToSize(this.descripcion, 180);
                    doc.text(splitTitle,20, 75);
                    
                    let NameReport="Reporte_"+"Folio_"+this.Id_muestra+"_"+this.nombreservico1+".pdf";
                    // doc.save("Reporte.pdf"); 
                    var img = new Image;
                    img.onload = function() {
                        doc.addImage(this,5, 5, 50, 20);                     
                        
                        doc.save(NameReport); 
                    };
                    img.crossOrigin = "";  // for demo as we are at different origin than image
                    img.src = "/assets/layout/images/sg.jpeg"; 


                    this.descuentoDialog = false;
                }

               
            },
            ImprimirEt(){
                
                if(this.muestra.id_muestra >0){
               
                this.publish.payload= this.etiquetasExteriores.toString()+','+this.etiquetasTubos.toString()+','+this.muestra.id_muestra.toString()+','+
			    this.muestra.paciente.toString()+','+this.muestra.pacienteFecha.toString()+','+this.SelectServicosImprimir.Nombre.toString()+','+this.muestra.doctor.toString();
                this.createConnection(); 
				  const { topic, qos, payload } = this.publish
					this.client.publish(topic, payload, qos, error => {
						 
						if (error) {
						console.log('Publish error', error)
						}
					})
					
			    this.publish.payload='';	 
                this.imprimirdialog=false;
                this.etiquetasTubos=1;
                this.etiquetasExteriores=1;
                this.SelectServicosImprimir=null;
                }
            },
            NewImprimir(){
                this.imprimirdialog=true;
                this.ServicosImprimir=[];
                for(var id1 in this.serviciosN)
                {                           
                    this.ServicosImprimir.push({"Nombre":this.serviciosN[id1].nombre});
                }
            },
            hideDialog1(){
                this.imprimirdialog=false;
            },
            computedDates (value) {
                return moment(value).format('YYYY-MM-DD')
            },

            computedDatesMx (value) {
                return moment(value).format('DD-MM-YYYY HH:mm')
            },
            computedDatesMxRepor (value) {
                return moment(value).format('DD-MM-YYYY')
            },
            createConnection() {
                const { host, port, endpoint, ...options } = this.connection
                const connectUrl = `wss://${host}:${port}${endpoint}`
                // const connectUrl = `ws://${host}:${port}${endpoint}`
                try {
                    this.client = mqtt.connect(connectUrl, options)
                    
                    console.log(this.client);
                } catch (error) {
                    console.log('mqtt.connect error', error)
                }
                this.client.on('connect', () => {
                    console.log('Connection succeeded!')
                })
                this.client.on('error', error => {
                    console.log('Connection failed', error)
                })
                this.client.on('message', (topic, message) => {
                    this.receiveNews = this.receiveNews.concat(message)
                    console.log(`Received message ${message} from topic ${topic}`)
                })
		    },
             
		}
	}
</script>


<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>
