import axios from 'axios';

export default class MuestraService {

	getMuestras() {
		//return axios.get('assets/layout/data/muestras.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/muestras`;
		const url = `/api/muestras`;
		return axios.get(url).then(res=>res.data.data);
	}
 
	getMuestrasID(id_muestra) {
		//return axios.get('http://localhost:8000/api/estados').then(res => res.data.data);
		//const url = `${API_URL}/api/estados/${estado.clave}`;
		//const url = ` https://sam.apicontrol.website/api/api/muestras/${id_muestra}`;
		const url = `/api/muestras/${id_muestra}`;
		return axios.get(url).then(res=>res.data.data);
	}

	UpdCancelarMuestras(id_muestra) {
		//return axios.get('http://localhost:8000/api/estados').then(res => res.data.data);
		//const url = `${API_URL}/api/estados/${estado.clave}`;
		//const url = ` https://sam.apicontrol.website/api/api/muestras/${id_muestra}`;
		const url = `/api/muestras/${id_muestra}`;
		return axios.put(url).then(res=>res.data.data);
	}

	getmMediotransportes() {
		//return axios.get('assets/layout/data/muestras.json').then(res => res.data.data);
		//const url = `https://sam.apicontrol.website/api/api/mediotransportes`;
		const url = `/api/mediotransportes`;
		return axios.get(url).then(res=>res.data.data);
	}

	getMuestrasExport() {
		//return axios.get('assets/layout/data/muestras.json').then(res => res.data.data);
		//const url = `https://sam.apicontrol.website/api/api/muestrasexport`;
		const url = `/api/muestrasexport`;
		return axios.get(url).then(res=>res.data.data);
	}

	
	getMuestrasConsultaDoctor() {
		//return axios.get('assets/layout/data/muestras.json').then(res => res.data.data);
		//const url = `https://sam.apicontrol.website/api/api/consultadoctores`;
		const url = `/api/consultadoctores`;
		return axios.get(url).then(res=>res.data.data);
	}

	getMuestrasConsultaID(id_muestra) {
		//return axios.get('http://localhost:8000/api/estados').then(res => res.data.data);
		//const url = `${API_URL}/api/estados/${estado.clave}`;
		//const url = `https://sam.apicontrol.website/api/api/muestras/${id_muestra}`;
		const url = `/api/muestras/${id_muestra}`;
		return axios.get(url).then(res=>res.data.data);
	}


	
}