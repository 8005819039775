import axios from 'axios';

export default class RecambioService {

    getRecambios() {
		//return axios.get('assets/layout/data/recambios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/recambiomedios`;
		const url = `/api/recambiomedios`;
		return axios.get(url).then(res=>res.data.data);
	}

	getrecambioserviceID(recambioservice) {
		//return axios.get('assets/layout/data/recambios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/recambiomedios/${recambioservice}`;
		const url = `/api/recambiomedios/${recambioservice}`;
		return axios.get(url).then(res=>res.data.data);
	}

	createrecambioservice(recambioservice) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/recambiomedios`;
		const url = `/api/recambiomedios`;
		return axios.post(url,recambioservice).then(res=>res.data.data);
	}
	
	updaterecambioservice(recambioservice) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/recambiomedios/${recambioservice.id_recambio_medio}`;
		const url = `/api/recambiomedios/${recambioservice.id_recambio_medio}`;
		return axios.put(url,recambioservice).then(res=>res.data.data);
	}
	
	deleterecambioservice(recambioservice) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/recambiomedios/${recambioservice.id_recambio_medio}`;
		const url = `/api/recambiomedios/${recambioservice.id_recambio_medio}`;
		return axios.delete(url).then(res=>res.data.data);
    }
}